.footer {
  width: 100%;
  height: fit-content;
  color: #fff;
  background-color: rgb(181, 38, 79);
}

.footer__wrapper {
  width: 325px;
  margin: auto;
  padding-top: 40px;
  display: grid;
  grid-template-rows: 50px repeat(4, max-content) 1fr;
  grid-row-gap: 30px;
}

.footer__logo-column {
  grid-row: 1;
  display: grid;
  align-items: start;
  justify-content: center;
}

.footer__logo-small {
  display: grid;
  align-items: center;
  justify-content: center;
}

.footer__logo-big {
  display: none;
}

.footer__services-column {
  display: grid;
  grid-template-rows: 20px repeat(2, max-content);
  justify-content: center;
  text-align: center;
  grid-row: 2;
}

.footer__company-column {
  display: grid;
  grid-template-rows: 20px repeat(2, max-content);
  justify-content: center;
  text-align: center;
  grid-row: 3;
}

.footer__legal-column {
  display: grid;
  grid-template-rows: 20px repeat(2, max-content);
  justify-content: center;
  text-align: center;
  grid-row: 4;
}

.footer__copyright-column {
  grid-row: 5;
  display: grid;
  align-items: end;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
}

.footer__column-title {
  font-family: "Helvetica-Medium";
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.footer__column-item {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  line-height: 17px;
  padding-top: 17px;
}

.footer__column-item:nth-child(1) {
  margin-top: 28px;
}

@media screen and (min-width: 992px) {
  .footer {
    height: 325px;
  }

  .footer__wrapper {
    width: 930px;
    height: 240px;
    padding-top: 50px;
    grid-template-rows: 1fr;
    grid-template-columns: 120px repeat(3, max-content) 1fr;
    grid-column-gap: 0px;
    position: relative;
  }

  .footer__logo-column {
    grid-column: 1;
    align-items: start;
    justify-content: start;
  }

  .footer__logo-big {
    display: block;
  }

  .footer__logo-small {
    display: none;
  }

  .footer__services-column {
    grid-column: 2;
    grid-row: 1;
    padding-left: 60px;
    justify-content: start;
    text-align: left;
  }

  .footer__company-column {
    grid-column: 3;
    grid-row: 1;
    padding-left: 60px;
    justify-content: start;
    text-align: left;
  }

  .footer__legal-column {
    grid-column: 4;
    grid-row: 1;
    padding-left: 60px;
    justify-content: start;
    text-align: left;
  }

  .footer__copyright-column {
    grid-column: 5;
    grid-row: 1;
    align-items: start;
    justify-content: end;
  }

  .footer__column-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }

  .footer__column-item {
    display: block;
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    line-height: 17px;
    padding-top: 17px;
  }

  .footer__column-item:nth-child(1) {
    margin-top: 28px;
  }
}
